/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDzwtdocmm2xYcvK00eOMqqHfCYbkwKlEU",
  "appId": "1:514559248833:web:d459de2800815e91a24740",
  "authDomain": "schooldog-i-vidalia-ga.firebaseapp.com",
  "measurementId": "G-6XWT58FRQ5",
  "messagingSenderId": "514559248833",
  "project": "schooldog-i-vidalia-ga",
  "projectId": "schooldog-i-vidalia-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-vidalia-ga.appspot.com"
}
